.profile-nft .mycollection-page {
    position: relative;
    padding: 200px 0 100px;
}

.profile-nft .mycollection-page .banner-area {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 80px;
}

.profile-nft .mycollection-page .banner-area .sec-title {
    padding-bottom: 0;
    width: 60%;
}

.profile-nft .mycollection-page .banner-area .btn-group {
    width: 40%;
    display: flex;
    justify-content: end;
}

.profile-nft .mycollection-page .banner-area .btn-group .btn-style-one {
    font-size: 16px;
    margin-right: 65px;
}

.profile-nft .mycollection-page .banner-area .btn-group .right-btn {
    position: relative;
    width: 49px;
    height: 49px;
    border: none;
    background-color: #FE9A3C;
}

.profile-nft .mycollection-page .banner-area .sec-title h2 {
    color: #FF6F3B;
    font-size: 56px;
    font-weight: 700;
    margin-bottom: 40px;
}

.profile-nft .mycollection-page .collection-box {}

.profile-nft .mycollection-page .collection-box .collection-inner {
    position: relative;
    margin-bottom: 30px;
    text-align: center;
    background-color: #F9F8FA;
}

.profile-nft .mycollection-page .collection-box .collection-inner .banner-img {
    position: relative;
}

.profile-nft .mycollection-page .collection-box .collection-inner .banner-img img {
    width: 100%;
}

.profile-nft .mycollection-page .collection-box .collection-inner .avatar-box {
    position: relative;
    margin-top: -30px;
}

.profile-nft .mycollection-page .collection-box .collection-inner .text-box {
    position: relative;
    padding: 25px;
}

.profile-nft .mycollection-page .collection-box .collection-inner .text-box h4 {
    font-size: 24px;
    font-weight: 600;
    color: #3A1688;
}

.profile-nft .mycollection-page .collection-box .collection-inner .text-box p {
    font-size: 18px;
    color: #1E3547;
    margin: 10px 0 20px;
}

.profile-nft .mycollection-page .collection-box .collection-inner .text-box a {
    color: #f6792b;
    font-size: 18px;
    font-weight: 500;
}

.profile-nft .mycollection-page .collection-box .collection-inner .text-box p a {
    font-style: italic;
    font-weight: 600;
}

.profile-nft .mycollection-page .banner-area .btn-group .btn-style-one {
    font-size: 13px;
    margin-right: 65px;
    padding: 10px 15px;
    height: 46px;
}

@media(max-width: 1450px) {
    .profile-nft .mycollection-page .banner-area .btn-group .btn-style-one {
        font-size: 13px;
        margin-right: 60px;
        padding: 10px 15px;
        height: 46px;
    }

    .profile-nft .mycollection-page .banner-area .btn-group .right-btn {
        width: 46px;
        height: 46px;
    }

    .profile-nft .mycollection-page .collection-box .collection-inner .text-box p,
    .profile-nft .mycollection-page .collection-box .collection-inner .text-box a {
        font-size: 16px;
    }

    .profile-nft .mycollection-page .collection-box .collection-inner .text-box h4 {
        font-size: 20px;
    }
}

@media(max-width: 991px) {

    .profile-nft .mycollection-page .banner-area .sec-title,
    .profile-nft .mycollection-page .banner-area .btn-group {
        padding-bottom: 0;
        width: 100%;
        display: block;
    }

    .catgery-sec .category-btn .list-category li .catgory-link {
        font-size: 14px;
    }
}

@media(max-width: 767px) {
    .catgery-sec .category-btn .list-category {
        list-style: none;
        display: flex;
        justify-content: center;
        margin-bottom: 0;
        border-bottom: 1px solid #ccc;
        flex-wrap: wrap;
    }

    .catgery-sec .category-btn .list-category li {
        width: 15%;
    }
}

@media(max-width: 665px) {

    .catgery-sec .category-btn .list-category li {
        width: 20%;
    }
}

@media(max-width: 565px) {

    .catgery-sec .category-btn .list-category li {
        width: 25%;
    }
}

@media(max-width: 480px) {
    .profile-nft .mycollection-page .banner-area .sec-title h2 {
        color: #FF6F3B;
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 13px;
    }
}

@media(max-width: 360px) {
    .profile-nft .mycollection-page .banner-area .btn-group .btn-style-one {
        font-size: 10px;
        margin-right: 54px;
        padding: 10px 15px;
        height: 43px;
    }

    .catgery-sec .category-btn .list-category li {
        width: 33.33%;
    }

    .profile-nft .mycollection-page .banner-area .btn-group .right-btn {
        height: 43px;
    }
}