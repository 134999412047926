* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-text {
  text-align: center;
  color: #61dafb;
}

.btn-style-one .landing-nft {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.auto-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1610px;
}

.btn-style-one {
  border-radius: 0;
  text-transform: uppercase;
  background-color: #FF6F3B;
  border: 0;
  box-shadow: none;
  color: #fff;
  transition: .3s linear;
  position: relative;
  padding: 12px 45px;
  font-size: 18px;
  display: inline-block;
}

.btn-style-one .icon {
  position: absolute;
  right: -45px;
  top: 0;
  background-color: #FE9A3C;
  color: #fff;
  padding: 16px 20px 17px;
  transition: .3s linear;
}

.btn-style-one:hover {
  background: #FE9A3C;
  color: #fff;
  text-decoration: none;
}

.btn-style-one:hover .icon {
  background-color: #FF6F3B;
}

/************ Modal  **************/

.modal .main-modal {
  top: 80px;
}

.main-modal .modal-content {
  background-color: #2f304f;
  border-radius: 25px;
  width: 650px !important;
}

.main-modal .modal-content .modal-header {
  border-bottom: none !important;
  padding: 40px 0px;
}

.main-modal .modal-content .modal-header .close {
  display: none;
}

.main-modal .modal-content .modal-header .modal-logo {
  position: absolute;
  left: 35%;
  top: 25px;
}

@media(max-width: 1600px) {
  .auto-container {
    max-width: 1410px;
  }
}

@media(max-width: 1440px) {
  .auto-container {
    max-width: 1210px;
  }
}

@media(max-width: 1350px) {
  .auto-container {
    max-width: 1150px;
  }
}